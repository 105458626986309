import ActionNode = require("Everlaw/UI/ActionNode");
import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import NewMessage = require("Everlaw/Messaging/NewMessage");
import Preference = require("Everlaw/Preference");
import { Recipient } from "Everlaw/Recipient";
import Rest = require("Everlaw/Rest");
import ResultsTableView = require("Everlaw/ResultsTableView");
import SavedResultsTableView = require("Everlaw/SavedResultsTableView");
import SearchResult = require("Everlaw/SearchResult");
import SearchResultsViewUtil = require("Everlaw/Search/SearchResultsViewUtil");
import SearchObjectPermission = require("Everlaw/Messaging/SearchSharing/SearchObjectPermission");
import { SearchObjectPermissionGrant } from "Everlaw/Messaging/SearchSharing/SearchObjectPermissionGrant";
import { SearchObjectPermissionConflictsDialog } from "Everlaw/Messaging/SearchSharing/SearchObjectPermissionConflictsDialog";
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import Tooltip = require("Everlaw/UI/Tooltip");
import Util = require("Everlaw/Util");
import dojo_on = require("dojo/on");

/**
 * Class for a new message that shares a search result. There is a lot of special permission
 * handling that needs to happen here to make sure recipients have access to all the objects
 * referenced in the search.
 */
class NewSearchMessage extends NewMessage {
    searchObjectPermission: SearchObjectPermission;
    searchObjectPermissionGrant: SearchObjectPermissionGrant;
    constructor(params: NewSearchMessage.Params) {
        super(params);
        this.attachmentClassInfo = ShareableObject.getClassInfo(this.attachment);
        this.searchObjectPermission = params.searchObjectPermission;
        if (!this.searchObjectPermission || !this.searchObjectPermission.hasInvalidTerms()) {
            this.searchObjectPermissionGrant = new SearchObjectPermissionGrant(
                this.searchObjectPermission,
            );
            this.toDestroy.push(this.searchObjectPermissionGrant);
        }
    }

    override hasInvalidAttachment(): boolean {
        return !(this.attachment instanceof SearchResult);
    }

    override canReceiveAttachment(recipient: Recipient): boolean {
        return !this.searchObjectPermission.shouldLockRecipient(recipient);
    }

    override canShareAttachment(): boolean {
        return super.canShareAttachment() && !!this.searchObjectPermissionGrant;
    }

    override onRecipientChange(recipient: Recipient, added: boolean): void {
        super.onRecipientChange(recipient, added);
        if (this.searchObjectPermissionGrant) {
            this.searchObjectPermissionGrant.changeObjPermGrant(recipient, added);
        }
    }

    override removeAttachment(): void {
        super.removeAttachment();
        this.searchObjectPermission = null;
        this.searchObjectPermissionGrant = null;
    }

    override send(params: NewMessage.SendParams): void {
        if (!this.attachment || !Util.onSearchPage()) {
            this._doSend(params);
            return;
        }
        const attachment = <SearchResult>this.attachment;
        const lastViewId = SearchResultsViewUtil.lastViewId();
        if (lastViewId) {
            if (attachment.defaultViewId) {
                this._doSend(params);
            } else {
                Rest.post("search/setDefaultView.rest", {
                    searchId: attachment.id,
                    viewId: lastViewId,
                }).then((data: SearchResult.RestResponse) => {
                    this.attachment = Base.set(SearchResult, data.search);
                    this._doSend(params);
                });
            }
            return;
        }
        // If the sharer is currently not on a saved results table view, first save the user's
        // current view as a custom ResultsTableView (not a SavedResultsTableView) so the recipients
        // can load the search in that view.
        const lastUsedView = Preference.SEARCH.visibleColumns.get();
        // save the custom view. lastUsedView should be a viewId if we're on the search page
        // and the search has a non-null viewId.
        const content = {
            searchId: attachment.id,
            visibleColumns: JSON.stringify(lastUsedView),
            sort: attachment.sort,
        };
        Rest.post("search/saveCustomView.rest", content).then((data: SearchResult.RestResponse) => {
            if (data.resultsTableView) {
                const view = Base.set(ResultsTableView, data.resultsTableView);
                Preference.SEARCH.visibleColumns.setUserValue(view.id);
            }
            this.attachment = Base.set(SearchResult, data.search);
            this._doSend(params);
        });
    }
}

module NewSearchMessage {
    /** Constructor params for NewSearchMessage */
    export interface Params extends NewMessage.Params {
        searchObjectPermission: SearchObjectPermission;
    }

    /** Composer class for sharing a search result. */
    export class Composer extends NewMessage.Composer {
        protected searchObjectSharingContainer: HTMLElement;
        protected subjectTooltip: Tooltip;
        protected attachmentTooltip: Tooltip;

        constructor(
            public override message: NewSearchMessage,
            params: NewMessage.ComposerParams,
        ) {
            super(message, params);
            if (this.subjectEditorHeader && this.messageAttachmentName) {
                this.buildTooltips();
            }
            if (this.dialog?._dialog) {
                Dom.style(this.dialog._dialog, "maxWidth", "720px");
            }
        }

        protected override getRecipientsWidgetRowParams(): {
            prepRowElement?: (recipient: Recipient) => BaseSelect.Row;
            icon?: (recipient: Recipient) => string;
            iconConfig?: (recipient: Recipient, icon: Icon) => void;
        } {
            if (!this.message.searchObjectPermissionGrant) {
                return {};
            }
            return {
                prepRowElement: (recipient: Recipient) => {
                    const row: BaseSelect.Row = {
                        node: Dom.div({
                            class: "table-row action description",
                            style: { position: "relative" },
                        }),
                        onDestroy: [],
                    };

                    // Lock current row if sharing Search Result and have Secured Object permission conflicts.
                    if (!this.message.canReceiveAttachment(recipient)) {
                        Dom.create(
                            "span",
                            {
                                class: "ellipsed label-node recipient-grey-out",
                                content: Dom.span(recipient.display()),
                            },
                            row.node,
                        );
                        Dom.addClass(row.node, "recipient-no-pointer-event");
                    } else {
                        Dom.create(
                            "span",
                            { class: "label-node", content: recipient.display() },
                            row.node,
                        );
                    }
                    return row;
                },
                icon: (recipient: Recipient) => {
                    if (!this.message.canReceiveAttachment(recipient)) {
                        return "lock-20";
                    }
                    return null;
                },
                iconConfig: (recipient: Recipient, icon: Icon) => {
                    this.toDestroy.push(
                        dojo_on(icon.node, Input.tap, (event: Event) => {
                            if (!this.message.canReceiveAttachment(recipient)) {
                                new SearchObjectPermissionConflictsDialog(
                                    recipient,
                                    this.message.searchObjectPermission,
                                );
                            }
                            event.stopPropagation();
                        }),
                    );
                    this.toDestroy.push(
                        dojo_on(icon.node, Input.enter, (event: Event) => event.stopPropagation()),
                    );
                    this.toDestroy.push(
                        dojo_on(icon.node, Input.leave, (event: Event) => event.stopPropagation()),
                    );

                    icon.tooltip = new Tooltip(
                        icon.node,
                        [
                            "Search cannot be sent due to permission conflicts.",
                            Dom.br(),
                            "Click the lock icon to view permission details.",
                        ],
                        ["after"],
                    );
                    this.toDestroy.push(icon.tooltip);

                    Dom.style(icon.node, { pointerEvents: "auto" });
                },
            };
        }

        protected override onRecipientChange(
            recipient: Recipient,
            added: boolean,
            changeShareButton = true,
        ): void {
            super.onRecipientChange(recipient, added);
            this.updateSearchObjectSharing();
            changeShareButton && this.onObjGrantingChange();
        }

        protected onObjGrantingChange(): void {
            if (this.dialog) {
                if (
                    this.message.searchObjectPermissionGrant
                    && !this.message.searchObjectPermissionGrant.isEmpty()
                ) {
                    this.dialog._submitButton.setContent("Grant permissions and share");
                    Dom.replaceClass(
                        this.dialog._submitButton,
                        "obj-perm-grant-button",
                        "one-width",
                    );
                } else {
                    this.dialog._submitButton.setContent("Share");
                    Dom.replaceClass(
                        this.dialog._submitButton,
                        "one-width",
                        "obj-perm-grant-button",
                    );
                }
            }
        }

        protected updateSearchObjectSharing() {
            if (this.searchObjectSharingContainer) {
                const showSearchObjectSharing =
                    this.message.searchObjectPermissionGrant
                    && !this.message.searchObjectPermissionGrant.isEmpty();
                Dom.show(this.searchObjectSharingContainer, showSearchObjectSharing);
            }
        }

        protected override nonAttachmentRecipientsPermissionConflicts(): string {
            return " will no longer receive the search due to search object permission conflicts.";
        }

        protected override removeAttachment(): void {
            super.removeAttachment();
            this.searchObjectSharingContainer && Dom.hide(this.searchObjectSharingContainer);
        }

        protected override initAttachmentPerms(): void {
            super.initAttachmentPerms();

            const showDetails = Dom.span(
                { class: "search-object-sharing-details" },
                "Show details",
            );
            this.toDestroy.push(
                new ActionNode(showDetails, {
                    onClick: () => this.showSearchObjectSharingDetails(),
                }),
            );
            this.searchObjectSharingContainer = Dom.div(
                { class: "search-object-sharing-container" },
                Icon.callout("alert-triangle-20", [
                    Dom.div(
                        "Some recipients will be granted ",
                        Dom.span({ class: "semi-bold" }, "view"),
                        " permissions on objects in this search. ",
                        Dom.node(showDetails),
                    ),
                ]),
            );
            Dom.place(this.searchObjectSharingContainer, this.attachmentPermsContainer, "after");

            // Only allow view sharing when on the search page, as the necessary results table view
            // objects and Preference.SEARCH.visibleColumns are only serialized to the search page.
            if (Util.onSearchPage()) {
                const tooltip: Dom.Content[] = [];
                const hasDefaultView = !!(<SearchResult>this.message.attachment).defaultViewId;
                if (hasDefaultView) {
                    tooltip.push("The search default view will apply for");
                } else {
                    const svId = SearchResultsViewUtil.getCurrentSavedViewId();
                    const view = svId ? Base.get(SavedResultsTableView, svId) : null;
                    if (view) {
                        tooltip.push(
                            "The view ",
                            Dom.b(view.name),
                            " will become the default view",
                        );
                    } else {
                        tooltip.push(
                            "Your ",
                            Dom.b("current"),
                            " results table view will become the default view",
                        );
                    }
                }

                tooltip.push(
                    " for all collaborators when opening the search for the first time. ",
                    "Users may customize their view afterwards, and changes will be personal to the user.",
                );

                const infoIcon = new Icon("info-circle-20", { tooltip: tooltip });
                Dom.place(infoIcon, this.attachmentPermsInitialRow);
            }

            // This method is called after the initial recipient list has been populated, so we need to
            // immediately update our search object sharing.
            this.updateSearchObjectSharing();
        }

        protected buildTooltips(): void {
            this.toDestroy.push(
                (this.subjectTooltip = new Tooltip.MirrorTooltip(this.subjectEditorHeader)),
                (this.attachmentTooltip = new Tooltip.MirrorTooltip(this.messageAttachmentName)),
            );
        }

        protected override setSubjectMode(editing: boolean) {
            super.setSubjectMode(editing);
            if (this.subjectEditorHeader && !editing) {
                this.toDestroy.push(
                    (this.subjectTooltip = new Tooltip.MirrorTooltip(this.subjectEditorHeader)),
                );
            }
        }

        showSearchObjectSharingDetails(): void {
            Dialog.ok("Object sharing details", this.message.searchObjectPermissionGrant.node);
        }

        protected override shouldShowAttachmentWarning(): boolean {
            return true;
        }

        protected override showAttachmentWarning(warnings: Dom.Content[]): void {
            if (warnings.length) {
                Dom.setContent(this.attachmentWarningMsgDiv, warnings);
                Dom.show(this.attachmentWarningContainer);
            }
        }

        protected override buildAttachmentWarningMessage(): Dom.Content[] {
            return this.message.searchObjectPermission.createWarningMessage(
                (<SearchResult>this.message.attachment).eql,
            );
        }
    }
}

export = NewSearchMessage;
