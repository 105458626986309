import {
    DialogSize,
    Icon,
    Link,
    Modal,
    Paragraph,
    Tooltip,
    useBrandedCallback,
} from "design-system";
import * as Help from "Everlaw/Help";
import { verifyClientDataAccessRequest } from "Everlaw/Help";
import * as Project from "Everlaw/Project";
import { PlatformMessageComposer } from "Everlaw/SmartOnboarding/Components/PlatformMessageComposer";
import * as React from "react";
import { FC, useId, useRef, useState } from "react";

const projectId = Project.getCurrentId();
const vpc = JSP_PARAMS.Server.vpc;
export const projectIdString = `${vpc} ${projectId ?? ""}`;

interface ContactDialogProps {
    show: boolean;
    onDialogClose: () => void;
}

enum RequestVerificationState {
    NOT_VERIFIED,
    VERIFIED,
    ERROR,
}

export const ContactDialog: FC<ContactDialogProps> = ({ show, onDialogClose }) => {
    const [showMessageComposer, setShowMessageComposer] = useState(false);
    const { organization, supportPartner } = Help.getSupportPartnerAndOrganization();
    const supportHours = Help.getSupportHoursAndStatus(organization, !!supportPartner);
    const verifyRequestInfoIconRef = useRef(null);
    const [requestVerified, setRequestVerified] = useState<RequestVerificationState>(
        RequestVerificationState.NOT_VERIFIED,
    );
    const verifyRequestInfoTooltipId = useId();
    const onVerifyClick = useBrandedCallback(() => {
        verifyClientDataAccessRequest().then(
            () => {
                setRequestVerified(RequestVerificationState.VERIFIED);
            },
            () => {
                setRequestVerified(RequestVerificationState.ERROR);
            },
        );
    }, []);
    return (
        <Modal
            size={DialogSize.LARGE}
            visible={show}
            onHide={() => {
                setShowMessageComposer(false);
                onDialogClose();
                setRequestVerified(RequestVerificationState.NOT_VERIFIED);
            }}
            primaryButton={null}
            secondaryButton={null}
            title={"Contact Support"}
        >
            <div className={"flex contact-dialog"}>
                <div className={"v-spaced-16"}>
                    <div>
                        <h3 className={"bb-text--semibold contact-dialog__header"}>Hours</h3>
                        <Paragraph>{supportHours.hours}</Paragraph>
                    </div>
                    <div className={"v-spaced-8"}>
                        <h3 className={"bb-text--semibold contact-dialog__header"}>Contact</h3>
                        <Paragraph>Phone: {Help.getSupportPhone(supportPartner)}</Paragraph>
                        <Paragraph>
                            Email:{" "}
                            <Link
                                href={"mailto:" + Help.getSupportEmail(supportPartner)}
                                newTab={true}
                            >
                                {Help.getSupportEmail(supportPartner)}
                            </Link>
                        </Paragraph>
                    </div>
                </div>
                <div className={"contact-dialog__right-panel v-spaced-16"}>
                    <div>
                        <Paragraph>Project ID: {projectIdString}</Paragraph>
                        {/*Mimics the behavior of HelpOverlay.ts in that we only show time zone if
                           project is defined. */}
                        {Project.CURRENT && (
                            <Paragraph>Timezone: {Project.CURRENT.timezoneId}</Paragraph>
                        )}
                    </div>
                    <div className={"v-spaced-8"}>
                        <h3 className={"bb-text--semibold contact-dialog__header"}>
                            Helpful Links
                        </h3>
                        <div className={"contact-dialog__links"}>
                            <Paragraph>
                                <Link href={"https://support.everlaw.com/hc/en-us"} newTab={true}>
                                    Knowledge Base
                                </Link>
                            </Paragraph>
                            <Paragraph>
                                <Link href={Help.trainingLink} newTab={true}>
                                    Training Center
                                </Link>
                            </Paragraph>
                            <Paragraph>
                                <Link href={"https://certification.everlaw.com/"} newTab={true}>
                                    Product Certification
                                </Link>
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <PlatformMessageComposer
                showMessageComposer={showMessageComposer}
                displayMessageComposer={() => setShowMessageComposer(true)}
                hideMessageComposer={() => setShowMessageComposer(false)}
            />
            <hr />
            <div className={"verify-request"}>
                {requestVerified === RequestVerificationState.NOT_VERIFIED
                    && "Submitted a support request via phone? "}
                <span className={"verify-request__text"}>
                    {requestVerified === RequestVerificationState.NOT_VERIFIED && (
                        <Link onClick={onVerifyClick}>Verify request</Link>
                    )}
                    {requestVerified === RequestVerificationState.VERIFIED && "Request verified"}
                    {requestVerified === RequestVerificationState.ERROR
                        && "Verification error. Please try again."}
                    <Icon.InfoCircle
                        className={"verify-request__info-icon"}
                        ref={verifyRequestInfoIconRef}
                        size={16}
                        tabIndex={0}
                        aria-describedby={verifyRequestInfoTooltipId}
                    />
                    <Tooltip id={verifyRequestInfoTooltipId} target={verifyRequestInfoIconRef}>
                        For security purposes, please verify you contacted Everlaw support
                    </Tooltip>
                </span>
            </div>
        </Modal>
    );
};
