import Base = require("Everlaw/Base");
import ChronWorkEntry = require("Everlaw/Chron/ChronologyWorkEntry");

class ChronologyWorkEntryRelationship extends Base.Object {
    type: string;
    workEntryId: number;
    name: string;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: any) {
        Object.assign(this, params);
    }
    get className() {
        return "ChronologyWorkEntryRelationship";
    }
    override display() {
        return this.name;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ChronologyWorkEntryRelationship {
    export function getRelationships(workEntry: ChronWorkEntry) {
        return Base.get(ChronologyWorkEntryRelationship).filter((rel) => {
            return rel.workEntryId === workEntry.id;
        });
    }
}

export = ChronologyWorkEntryRelationship;
