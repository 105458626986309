import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import Bugsnag = require("Everlaw/Bugsnag");
import Core = require("Everlaw/Core");
import DateUtil = require("Everlaw/DateUtil");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import DomText = require("Everlaw/Dom/Text");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import Str = require("Everlaw/Core/Str");
import User = require("Everlaw/User");
import { UserObject } from "Everlaw/UserObject";
import array = require("dojo/_base/array");
import dojo_date = require("dojo/date");
import XRegExp = require("xregexp");
import HomepageFolder = require("Everlaw/HomepageFolder");

type MessageParams = {
    id: Message.Id;
    parent: Message.Id;
    sender: number;
    text: string;
    favorite: boolean;
    recipients: string;
    timeSent: number;
    read: boolean;
    attachmentObject: Base.Object;
    attachmentId: number;
    attachmentClass: string;
    attachmentSize: number;
};

const MESSAGE_METADATA_REGEX: RegExp = XRegExp(
    "On \\w{3} \\w{3} \\d{1,2}, \\d{4}, at \\d{1,2}:\\d{1,2} [a|p]m, .+ wrote:",
);

class Message extends UserObject {
    get className() {
        return "Message";
    }
    override id: Message.Id;
    sender: User.Id;
    recipients: string;
    timeSent: number;
    text: string;
    parent: Message.Id;
    attachmentClass: string;
    attachmentId: number;
    attachmentObject: Base.Object;
    read: boolean;
    override favorite: boolean;
    // the text which appears above the "three dot menu"
    private _untrimmedText: string;
    // the text which appears below the "three dot menu"
    private _trimmedText: string;
    isFirstInThread: boolean;
    private textInitialized = false;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: MessageParams) {
        Object.assign(this, params);
        // Get the attachment as an EBO.
        if (params.attachmentObject) {
            const objectClass = ShareableObject.getClassInfo(this.attachmentClass).objectClass;
            Base.set(objectClass, params.attachmentObject);
            if (objectClass === HomepageFolder) {
                // For this special case, we also want to add newly shared subfolders to the base store.
                // Since we can't attach multiple objects to a message, we need to fetch the subfolders.
                Rest.post("getSubfolders.rest", {
                    folderId: params.attachmentId,
                }).then((data) => {
                    Base.set(HomepageFolder, data);
                });
            }
            // params.attachmentObject may not be the attachment itself
            this.attachmentObject =
                Base.get(objectClass, this.attachmentId) || this.attachmentObject;
            if (params.attachmentSize) {
                Object.assign(this.attachmentObject || {}, { size: params.attachmentSize });
            }
        }
    }
    override compare(other: Message) {
        return other.id - this.id;
    }
    displaySender() {
        return ShareableObject.displaySid(String(this.sender));
    }
    displayRecipients() {
        return array.map(this.getRecipients(), ShareableObject.displaySid).join(", ");
    }
    getRecipients(): string[] {
        return this.recipients.split(",");
    }
    summary() {
        return Str.ellipsify(DomText.htmlToText(this.text), 120);
    }
    sentByMe() {
        return this.sender === User.me.id;
    }
    override setFavorite(isFavorite: boolean, callback?: () => void) {
        Message.batchFavorite(this.id, isFavorite, callback);
    }
    displayTimeSent(): string {
        return DateUtil.getMessageTimestamp(this.timeSent);
    }
    hasMultipleParties(): boolean {
        return this.getRecipients().length > 1;
    }
    // Lazily figure out which part of the message text should be trimmed (below the three dot menu)
    private trimText() {
        this.textInitialized = true;
        if (this.isFirstInThread) {
            this._untrimmedText = this.text;
            return;
        }
        try {
            this.trimTextHelper();
        } catch (e) {
            // If we run into an unexpected error while trimming a message, set it to untrimmed
            // and notify bugsnag so the message loads.
            Bugsnag.notify(e);
            this._trimmedText = null;
            this._untrimmedText = this.text;
        }
    }

    private trimTextHelper() {
        const upper = Dom.div();
        const lower = Dom.div();
        upper.innerHTML = this.text;
        lower.innerHTML = this.text;
        let hitBlockQuote = false;
        const numElements = upper.children.length;
        for (let childIdIndex = 0; childIdIndex < numElements; childIdIndex++) {
            const upperChild = upper.children[childIdIndex];
            const tagName = upperChild.tagName;
            if (tagName === "BLOCKQUOTE") {
                upper.removeChild(upperChild);
                // the "On such and such Date, so and so wrote" line
                const child = upper.children[childIdIndex - 1];
                if (child) {
                    upper.removeChild(child);
                }
                hitBlockQuote = true;
                // If we get here and there are multiple elements remaining, don't trim anything.
                if (childIdIndex < numElements - 1) {
                    this._untrimmedText = this.text;
                    return;
                }
            } else {
                // If we hit a non block quote after previously hitting a block quote, that means
                // this message has some inline reply and we can't shorten it.
                if (hitBlockQuote) {
                    this._untrimmedText = this.text;
                    return;
                }
                lower.removeChild(lower.firstChild);
            }
        }
        const remainingUpperChildren = upper.children.length;
        if (remainingUpperChildren) {
            const lastChild = upper.children[remainingUpperChildren - 1];
            if (Message.isMetadataLine(lastChild.textContent)) {
                upper.removeChild(lastChild);
            }
        }
        this._untrimmedText = upper.innerHTML;
        this._trimmedText = lower.innerHTML;
    }

    private static isMetadataLine(textLine: string): boolean {
        return XRegExp.test(textLine, MESSAGE_METADATA_REGEX);
    }
    get untrimmedText(): string {
        if (!this.textInitialized) {
            this.trimText();
        }
        return this._untrimmedText;
    }
    get trimmedText(): string {
        if (!this.textInitialized) {
            this.trimText();
        }
        return this._trimmedText;
    }
    hasTrimmedText(): boolean {
        return !!this.trimmedText;
    }
    allParties(): string[] {
        const recipients = this.getRecipients().filter((userId) => userId !== String(User.me.id));
        return Arr.unique([String(this.sender), ...recipients]);
    }
}

module Message {
    export type Id = number & Base.Id<"Message">;

    export const messageSent = new Core.Channel<{}>();
    export const countChanged = new Core.Channel<{ count: number }>();

    export function batchFavorite(id: number | number[], favorite: boolean, callback?: () => void) {
        const ids = Arr.wrap(id);
        if (ids.length === 0) {
            const prefix = favorite ? "" : "non-";
            Dialog.ok(
                "No messages selected",
                "Check-select one or more messages to batch mark " + prefix + "favorite",
            );
            return;
        }
        Rest.post("messages/favorite.rest", {
            ids: ids,
            favorite: favorite,
        }).then(
            (data: number[]) => {
                // data is an array of messages that have been sucessfully marked favorite/nonfavorite
                const msgs = Base.get(Message, data);
                array.forEach(msgs, (msg) => {
                    msg.favorite = favorite;
                });
                Base.publish(msgs);
                callback && callback();
            },
            () => {
                const msgEnding = favorite ? "favorite" : "non-favorite";
                Dialog.ok(
                    "Error",
                    `Could not mark ${Str.pluralForm("message", ids.length)} as ${msgEnding}`,
                );
            },
        );
    }

    export function setRead(
        id: number | number[],
        read: boolean,
        thread: boolean,
        callback?: (unreadChange: number) => void,
    ): void {
        const ids = Arr.wrap(id);
        if (ids.length === 0) {
            const prefix = read ? "" : "un";
            Dialog.ok(
                "No messages selected",
                "Check-select one or more messages to batch mark " + prefix + "read",
            );
            return;
        }
        Rest.post("messages/read.rest", {
            ids: ids,
            read: read,
            thread: thread,
        }).then(
            (data: { unreadChange: number }) => {
                if (thread) {
                    const threads = Base.get(MessageThread, ids);
                    threads.forEach((thread) => thread.setRead(read));
                    Base.publish(threads);
                } else {
                    const messages = Base.get(Message, ids);
                    messages.forEach((message) => (message.read = read));
                    Base.publish(messages);
                }
                callback && callback(data.unreadChange);
            },
            () => {
                const msgEnding = read ? "read" : "unread";
                if (ids.length === 1) {
                    Dialog.ok("Error", "Could not mark message as " + msgEnding);
                } else {
                    Dialog.ok("Error", "Could not mark messages as " + msgEnding);
                }
            },
        );
    }

    export function setNewMessagesCount(count: number) {
        Dom.ifNodeExists("message-count", (countEl) => {
            // Only update if the count is different from before; this prevents the animation from running
            // simply when the long-poll has expired.
            if (count) {
                countEl.textContent = String(count);
                Dom.show(countEl);
            } else {
                Dom.hide(countEl);
            }
            countChanged.publish({ count });
        });
    }

    export function quoteText(message: Message): string {
        const senderInfo = `<p></p>On ${DateUtil.getMessageTimestampLong(message.timeSent)}, ${message.displaySender()} wrote:`;
        return senderInfo + "<blockquote>" + message.text + "</blockquote>";
    }

    export function wrapWithMetadata(msg: Message, thread: MessageThread): string {
        return (
            "<br><br>---------- Forwarded message ----------<br>"
            + "<b>From:</b> "
            + DomText.escapeHtml(msg.displaySender())
            + "<br>"
            + "<b>To:</b> "
            + DomText.escapeHtml(msg.displayRecipients())
            + "<br>"
            + "<b>Date:</b> "
            + DateUtil.fullMessageTimestamp(msg.timeSent)
            + "<br>"
            + "<b>Subject:</b> "
            + DomText.escapeHtml(thread.getSubject())
            + "<br>"
            + msg.text
        );
    }

    export type ThreadId = number & Base.Id<"MessageThread">;

    type MessageThreadParams = {
        subject: string;
        id: ThreadId;
        messages: Message[];
        messageCount: number;
        unread: boolean;
        attachmentClass: string;
    };

    export class MessageThread extends Base.Object {
        subject: string;
        override id: ThreadId;
        messages: Message[];
        messageCount: number;
        unread: boolean;
        private isLoaded = false;
        attachmentClass: string;
        get className(): string {
            return "MessageThread";
        }
        constructor(params: MessageThreadParams) {
            super(params);
            this._mixin(params);
        }
        override _mixin(params: MessageThreadParams): MessageThread {
            Object.assign(this, params);
            if (params.messages) {
                this.messages = Base.set(Message, params.messages);
            }
            this.invalidate();
            return this;
        }
        static loadThread(
            id: ThreadId,
            thread?: MessageThread,
            callback?: () => void,
        ): Promise<MessageThread> {
            return Rest.get("messages/loadMessages.rest", { id: id }).then((threadData) => {
                const loadedThread = thread
                    ? thread._mixin(threadData)
                    : new MessageThread(threadData);
                loadedThread.isLoaded = true;
                loadedThread.messages[loadedThread.messages.length - 1].isFirstInThread = true;
                loadedThread.unread = false;
                callback && callback();
                return loadedThread;
            });
        }
        /**
         * This method should be called before calling any methods which access this.messages
         * (except #getMostRecentMessage).
         */
        ensureLoaded(callback?: () => void): Promise<MessageThread> {
            if (this.isLoaded) {
                callback && callback();
                return Promise.resolve(this);
            } else {
                return MessageThread.loadThread(this.id, this, callback);
            }
        }
        addNewMessage(message: Message): void {
            this.messages.unshift(message);
            this.messageCount++;
            Base.publish(this);
        }
        getMostRecentMessage(): Message {
            return this.messages[0];
        }
        updated(): number {
            return this.getMostRecentMessage().timeSent;
        }
        setRead(read: boolean): void {
            this.messages.forEach((msg) => (msg.read = read));
            this.unread = false;
        }
        getSubject(): string {
            return this.subject ? this.subject : "(No subject)";
        }
        sendersText(): string {
            const parties = this.getMostRecentMessage().allParties();
            let summary = parties.map((party) => ShareableObject.displaySid(`${party}`)).join(", ");
            if (this.messageCount > 1) {
                summary += ` (${this.messageCount})`;
            }
            return summary;
        }
        timeUpdatedText(): string {
            const d = new Date(this.updated());
            const now = new Date();
            if (dojo_date.compare(d, now, "date") === 0) {
                // Today; only show time
                return DateUtil.displayTimeLocal(this.updated());
            } else {
                // show it all
                return DateUtil.displayShortDateShortYearLocal(this.updated());
            }
        }
        unreadMessages(): Message[] {
            return this.messages.filter((msg) => !msg.read);
        }
        deleteMessage(messageId: Message.Id): Promise<void> {
            return Rest.post("messages/deleteMessage.rest", { id: messageId }).then(() => {
                this.messages = this.messages.filter((msg) => msg.id !== messageId);
                this.messageCount = this.messages.length;
            });
        }
        isEmpty(): boolean {
            return this.messageCount === 0;
        }
        viewThreadLink(): string {
            return `/${Project.CURRENT.id}/messages.do#id=${this.id}`;
        }
        invalidate(): void {
            this.isLoaded = false;
        }
    }
}
export = Message;
