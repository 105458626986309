import { ColorTokens, useEventListener, useBrandedCallback } from "design-system";
import { AccessLevel } from "Everlaw/AdminTabs/ClientDataAccessRequest";
import { EVERID, setEverId } from "Everlaw/EverAttribute/EverId";
import * as Preference from "Everlaw/Preference";
import { RecommendationHelpMenu } from "Everlaw/SmartOnboarding/Components/RecommendationHelpMenu";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import { recommendationsLoaded } from "Everlaw/SmartOnboarding/RecommendationSharedVariables";
import * as RecommendationNavigationPage from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import { StepDisplayer } from "Everlaw/SmartOnboarding/RecommendationStep";
import { CURRENT_ORG, Organization } from "Everlaw/Organization";
import { IconButton } from "Everlaw/UI/Button";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as User from "Everlaw/User";
import * as React from "react";
import { FC, MutableRefObject, ReactNode, RefObject, useImperativeHandle, useState } from "react";
import { Link, PopoverPlacement } from "design-system";
import Dom = require("Everlaw/Dom");
import FloatingPanel = require("Everlaw/UI/FloatingPanel");
import * as Input from "Everlaw/Input";
import Is = require("Everlaw/Core/Is");
import Util = require("Everlaw/Util");
import { Partner } from "Everlaw/Partner";
import * as Project from "Everlaw/Project";
import Str = require("Everlaw/Core/Str");
import { CreateBuyNewCaseForm } from "Everlaw/MarketoForm";
import * as moment from "moment-timezone";
import WalkMe = require("Everlaw/WalkMe");
import * as Rest from "Everlaw/Rest";
import * as Icon from "Everlaw/UI/Icon";

export const supportPhone = JSP_PARAMS.Help.supportPhone;
export const supportEmail = JSP_PARAMS.Help.supportEmail;
export const trainingLink = JSP_PARAMS.Help.trainingLink;
export const inHouseSupportTimezoneId = JSP_PARAMS.Help.supportTimezoneId;
export const inHouseSupportHours = JSP_PARAMS.Help.supportHours;
// If both weekend support hours are set to -1, there's no weekend support for this VPC
export const hasInHouseSaturdaySupport =
    inHouseSupportHours.saturday.open !== -1 && inHouseSupportHours.saturday.close !== -1;
export const hasInHouseSundaySupport =
    inHouseSupportHours.sunday.open !== -1 && inHouseSupportHours.sunday.close !== -1;
export const inHouseSupportMessage = "Need help? We're here for you!";
export const CLOSED_STRING = "Closed";
export const TERMS_OF_USE_LINK = "https://www.everlaw.com/terms-of-use/";
export const PRIVACY_NOTICE_LINK = "https://www.everlaw.com/privacy-notice/";
export const CALIFORNIA_PRIVACY_LINK =
    "https://www.everlaw.com/privacy-notice/#california-resident-privacy-rights";

export let pageName: string = JSP_PARAMS.Help.pageName;
export function setHelpPageName(name: string) {
    pageName = name;
}

export let viewAllPage = JSP_PARAMS.Help.helpPage;
export function setViewAllPage(page: string) {
    viewAllPage = page;
}

export function addHelp(parent: Dom.Nodeable, leadinPrompt: string) {
    const emailLink = makeEmailLink("everblue-link-bold");
    const content: Dom.Content = [Dom.div(leadinPrompt), emailLink];
    if (!Str.isNullOrWhitespace(supportPhone)) {
        content.push(" or call us at ", supportPhone);
    }
    Dom.create(
        "div",
        {
            class: "help-support-details",
            content,
        },
        parent,
    );
}

interface EmailLinkProps {
    className?: string;
    supportEmail?: string;
    children?: ReactNode;
}

export const EmailLink: FC<EmailLinkProps> = ({
    className,
    supportEmail = JSP_PARAMS.Help.supportEmail,
    children = `Email ${supportEmail}`,
}) => {
    return (
        <Link
            href={`mailto:${supportEmail}`}
            className={className}
            newTab={true}
            onClick={() => ga_event("Help", "Send Email")}
        >
            {children}
        </Link>
    );
};

export function makeEmailLink(
    textClass: string,
    supportEmail: string = JSP_PARAMS.Help.supportEmail,
    emailUs = true,
) {
    return Dom.create("a", {
        href: "mailto:" + supportEmail,
        class: textClass,
        target: "_blank",
        rel: "noopener noreferrer",
        onclick: function () {
            ga_event("Help", "Send Email");
        },
        content: "Email " + (emailUs ? "us" : supportEmail),
    });
}

export function makeOpenNewCaseForm(textClass: string) {
    // A button that opens a modal dialog.
    return wrapReactComponent(CreateBuyNewCaseForm, {
        buttonStyle: textClass,
        isHelpPage: true,
    }).getNode();
}
export const learnMoreLinks = {
    Assignments:
        "https://support.everlaw.com/hc/en-us/articles/210222443-Introduction-to-Assignments",
    Search: "https://support.everlaw.com/hc/en-us/articles/205466645-Search-Overview-and-Examples",
    Binders: "https://support.everlaw.com/hc/en-us/articles/209619346-Binders",
    Chronology:
        "https://support.everlaw.com/hc/en-us/articles/206439403-Introduction-to-StoryBuilder",
    LanguageTools:
        "https://support.everlaw.com/hc/en-us/articles/360024408132-General-Settings#h_c1387724-76c9-416e-b8a9-3b1e205d305b",
};

// Assumes hour is an integer between 0 and 24
export function formatHour(hour: number, minute: number) {
    const minuteString = minute === 0 ? "" : (minute < 10 ? ":0" : ":") + minute;
    if (hour <= 0 || hour === 24) {
        return "12" + minuteString + " am";
    }
    if (hour === 12) {
        return "12" + minuteString + " pm";
    }
    if (hour > 24) {
        return formatHour(hour % 24, minute);
    }
    if (hour > 12) {
        return hour - 12 + minuteString + " pm";
    }
    return hour + minuteString + " am";
}

/**
 * This function will truncate to the hour (e.g. 4:30 pm == 4 pm), assumes that the opening
 * hour is am and the closing hour is pm, and the opening and closing hours are divided
 * by a "-" (e.g. "8 am -5 pm").
 */
export function parseSupportHours(partnerSupportHours: String) {
    if (!partnerSupportHours) {
        return null;
    }
    const tokens = partnerSupportHours.split("-");
    // Return if hours are not in expected format (eg 8am - 8pm)
    if (tokens.length !== 2) {
        return null;
    }

    const openSplit = tokens[0].split(":");
    let hourOpen = openSplit.length > 0 && Util.toInt(openSplit[0].replace(/[^0-9]+/g, ""));
    let minuteOpen = openSplit.length > 1 && Util.toInt(openSplit[1].replace(/[^0-9]+/g, ""));
    const closedSplit = tokens[1].split(":");
    let hourClosed = closedSplit.length > 0 && Util.toInt(closedSplit[0].replace(/[^0-9]+/g, ""));
    let minuteClosed = closedSplit.length > 1 && Util.toInt(closedSplit[1].replace(/[^0-9]+/g, ""));

    if (!hourOpen || !hourClosed) {
        return null;
    }

    if (hourOpen < 12 && tokens[0].toLowerCase().indexOf("pm") !== -1) {
        hourOpen += 12;
    }

    if (!minuteOpen || minuteOpen > 59) {
        minuteOpen = 0;
    }

    if (!minuteClosed || minuteClosed > 59) {
        minuteClosed = 0;
    }

    // convert to 24-hour time
    if (hourClosed < 12 && tokens[1].toLowerCase().indexOf("pm") !== -1) {
        hourClosed += 12;
    }
    return { open: hourOpen, openMin: minuteOpen, close: hourClosed, closeMin: minuteClosed };
}

export enum SupportStatus {
    open,
    closed,
    unknown,
}

export function getSupportHoursAndStatus(
    org?: Organization,
    partnerSupported = false,
    dayOfWeek = new Date().getDay(),
) {
    let openHour: number;
    let closeHour: number;
    let openMin = 0;
    let closeMin = 0;
    let daysOfWeek;
    let rawHourString;
    switch (dayOfWeek) {
        case 0:
            daysOfWeek = "Sunday: ";
            if (org && org.sundayHours) {
                rawHourString = org.sundayHours;
            } else if (!partnerSupported && hasInHouseSundaySupport) {
                openHour = inHouseSupportHours.sunday.open;
                closeHour = inHouseSupportHours.sunday.close;
            } else {
                return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
            }
            break;
        case 6:
            daysOfWeek = "Saturday: ";
            if (org && org.saturdayHours) {
                rawHourString = org.saturdayHours;
            } else if (!partnerSupported && hasInHouseSaturdaySupport) {
                openHour = inHouseSupportHours.saturday.open;
                closeHour = inHouseSupportHours.saturday.close;
            } else {
                return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
            }
            break;
        default:
            daysOfWeek = "Monday – Friday: ";
            if (org && org.weekdayHours) {
                rawHourString = org.weekdayHours;
            } else if (!partnerSupported) {
                openHour = inHouseSupportHours.weekday.open;
                closeHour = inHouseSupportHours.weekday.close;
            } else {
                return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
            }
    }

    const supportTimezone =
        org && org.timezoneId
            ? org.timezoneId
            : moment.tz(new Date(), inHouseSupportTimezoneId).format("z");
    if (rawHourString) {
        if (rawHourString.toLowerCase().trim() === "closed") {
            return { hours: daysOfWeek + CLOSED_STRING, status: SupportStatus.closed };
        }
        const parsedHourString = parseSupportHours(rawHourString);
        if (!parsedHourString) {
            return {
                hours: daysOfWeek + rawHourString.toLowerCase(),
                status: SupportStatus.unknown,
            };
        }
        openHour = parsedHourString.open;
        openMin = parsedHourString.openMin;
        closeHour = parsedHourString.close;
        closeMin = parsedHourString.closeMin;
    }
    if (!Is.defined(openHour) || !closeHour) {
        return {
            hours: "Contact Everlaw support or your Everlaw partner for support hours",
            status: SupportStatus.unknown,
        };
    }

    if (closeHour - openHour >= 24) {
        // Open 24 hours or greater than 24 hours, so just say 24 hour coverage
        return {
            hours: daysOfWeek + "24 hour coverage",
            status: SupportStatus.open,
        };
    }
    const supportHours =
        formatHour(openHour, openMin) + " – " + formatHour(closeHour, closeMin) + " ";

    return {
        hours: daysOfWeek + supportHours + supportTimezone,
        status: checkIfInhouseSupportOpen(openHour, openMin, closeHour, closeMin, supportTimezone),
    };
}

export function checkIfInhouseSupportOpen(
    hourOpen: number,
    minOpen: number,
    hourClosed: number,
    minClosed: number,
    timezoneId: string,
): SupportStatus {
    // If the org's support time zone isn't a legitimate timezone id,
    // guess at the user's time zone instead
    const timezone = moment.tz.zone(timezoneId) ? timezoneId : moment.tz.guess();

    let hourOfDay = parseInt(moment().tz(timezone).format("HH"));
    const currentMinute = parseInt(moment().tz(timezone).format("mm"));
    if (hourClosed < hourOpen || (hourClosed === hourOpen && minOpen >= minClosed)) {
        hourClosed += 24;
    }
    if (hourOfDay < hourOpen) {
        hourOfDay += 24;
    }
    const isOpen =
        (hourOfDay > hourOpen || (hourOfDay === hourOpen && currentMinute >= minOpen))
        && (hourOfDay < hourClosed || (hourOfDay === hourClosed && currentMinute <= minClosed));
    return isOpen ? SupportStatus.open : SupportStatus.closed;
}

export function getSupportPhone(partner?: Partner) {
    return (partner && partner.phone) || supportPhone;
}

export function getSupportEmail(partner?: Partner) {
    return (partner && partner.email) || supportEmail;
}

export function getSupportBy(partner?: Partner) {
    return partner && partner.organization && partner.organization.name
        ? "Supported by: " + partner.organization.name
        : inHouseSupportMessage;
}

function helpColumn(...sections: Node[]) {
    return Dom.div(
        {
            style: {
                display: "flex",
                flexDirection: "column",
            },
        },
        ...sections,
    );
}

function helpSection(title: string, ...entries: string[]) {
    const entryNodes = entries.map((entry: string) => {
        return Dom.div({}, entry);
    });
    return Dom.div(
        {
            style: {
                marginBottom: "24px",
            },
        },
        Dom.h6(
            {
                style: {
                    margin: "8px 0 8px",
                    fontSize: "0.9rem",
                },
            },
            title,
        ),
        ...entryNodes,
    );
}

export function advancedContentSearchHelpIcon(): IconButton {
    const lazyPanel = getHelpPanel(
        getAdvancedContentSearchHelp(),
        720,
        516,
        "Content search types and examples",
    );

    return new IconButton({
        iconClass: "info-circle-20",
        // The default Input.tap does not correctly capture all clicks, while Input.press does.
        suppressDojoEvent: "press",
        tooltip: "View content search types and examples",
        onClick: () => {
            lazyPanel().toggle();
        },
    });
}

function getAdvancedContentSearchHelp(): HTMLElement {
    return Dom.div(
        Dom.div({ class: "focus-div", tabIndex: "0" }),
        Dom.div(
            { class: "content-search-help-panel-2-cols" },
            helpColumn(
                helpSection(
                    "Boolean",
                    '"first snow" AND "time to ski"',
                    '"not today" OR raincheck',
                    '"not today" raincheck',
                ),
                helpSection(
                    "Wildcard",
                    "Pa?t → Past, Part, Pant",
                    "Pa*t → Pat, Parent, Patient, Patent",
                    "/.*end/ → Lend, Blend, Ascend",
                ),
                helpSection(
                    "Proximity",
                    '"Werewolf village"~10',
                    '"("Escape from")("Werewolf village")"~20',
                ),
                helpSection("Proximity with word order", '"Werewolf village"~~30'),
            ),
            helpColumn(
                helpSection("Fuzzy", "Pass~ → Past, Passes, Mass, Part"),
                helpSection(
                    "Regular expressions",
                    "/[1-5][0-9]/ → Numbers 10–59",
                    "/ma+d/ → mad, maad, maaad, etc.",
                ),
                helpSection(
                    "Smart expressions",
                    "<ssn>",
                    "<phone>",
                    "<email>",
                    "<ein> → Employee Identification number",
                    "<iban> → International Bank Account Number",
                    "<credit-card> → Credit card numbers",
                    "<phone=805*> → 805-444-9876, (805) 555-1234, etc.",
                ),
            ),
        ),
        learnMoreLink(
            "https://support.everlaw.com/hc/en-us/articles/210132673-Advanced-Content-Searches-Wildcard-Proximity-Fuzzy-Regular-Expression-",
        ),
    );
}

export function getMessagesSearchHelp() {
    return Dom.div(
        { style: { float: "left" } },
        Dom.div({ class: "focus-div", tabIndex: "0" }),
        helpColumn(
            helpSection("Basic", "zebra lion tiger"),
            helpSection(
                "Content",
                "text:aardvark",
                "text:shar*",
                "subject:(spotted giraffe)",
                "attachment:(Important binder)",
            ),
            helpSection("Users", "from:reviewer~5", "to:Alice"),
            learnMoreLink(
                "https://support.everlaw.com/hc/en-us/articles/205083209-Message-Center-Overview#h_01EZD82ZA75JQXKZ8TC6D5BRAC",
            ),
        ),
    );
}

function getUploadTypeHelp() {
    const nativeDataText =
        "Files in their original, or raw, state that have not "
        + "been processed or produced previously. Typically, these files are collected "
        + "directly from a custodian's device. A native data set may comprise many different file "
        + "types that require different applications to open.";
    const processedDataText =
        "Files that have already been loaded into, "
        + "and then produced out of, a processing tool. Processed data is usually composed "
        + "of Bates-stamped documents, includes a load file, "
        + "and has a well-defined folder structure: there are separate folders for the "
        + "images, text, and any natives included in the data set.";

    return Dom.div(
        Dom.div(
            { class: "focus-div", tabIndex: "0" },
            Dom.span({ class: "semi-bold" }, "Native data: "),
            Dom.span(nativeDataText),
            Dom.p("During native upload, Everlaw:"),
            Dom.ul(
                Dom.li("Organizes the files and assigns a control number"),
                Dom.li("Extracts attachments, embedded documents, metadata, and text"),
                Dom.li("Creates PDF versions of the documents"),
            ),
            Dom.span({ class: "semi-bold" }, "Processed data: "),
            Dom.span(processedDataText),
            Dom.p(
                "Everlaw's processed uploader can also upload processed PDF's without a load file.",
            ),
            Dom.p(
                "Examples of processed data include received productions, data migrating "
                    + "from other ediscovery platforms, and PDFs created by scanning "
                    + "hard copy documents.",
            ),
            Dom.p(
                "During processed upload, Everlaw uses the load file (if present) "
                    + "to link the associated metadata and image, text, and any included "
                    + "native files for each document.",
            ),
        ),
        Dom.a(
            {
                href: "https://support.everlaw.com/hc/en-us/articles/27298871569563-Distinguish-between-Native-and-Processed-Data",
                target: "_blank",
                style: {
                    color: ColorTokens.TEXT_LINK,
                },
            },
            Dom.span({ class: "margin-right-4" }, "Learn more about native and processed data"),
            new Icon("arrow-up-right-blue-20").getNode(),
        ),
    );
}

export function uploadTypeHelpIcon(): IconButton {
    const uploadHelpPanel = getHelpPanel(getUploadTypeHelp(), 456, 678, "Upload types");

    return new IconButton({
        iconClass: "info-circle-20",
        className: "upload-type-icon",
        // The default Input.tap does not correctly capture all clicks, while Input.press does.
        suppressDojoEvent: "press",
        tooltip: "View information about upload types",
        onClick: () => {
            uploadHelpPanel().toggle();
        },
    });
}

function learnMoreLink(href: string) {
    return Dom.a(
        {
            href: href,
            target: "_blank",
            rel: "noopener noreferrer",
            style: {
                color: ColorTokens.TEXT_LINK,
            },
        },
        "Learn more",
    );
}

export function getHelpPanel(
    content: HTMLElement,
    minWidth: number,
    minHeight: number,
    title: string,
): () => FloatingPanel {
    return Util.lazy(() => {
        return new FloatingPanel({
            title: title,
            class: "dialog-created-floating-panel",
            content: content,
            minWidth: minWidth,
            minHeight: minHeight,
            resizable: false,
            listenForCloseEvents: true,
            onToggle: (open, fromX) => {
                open
                    && setTimeout(() => {
                        Dom.firstChild(content, "focus-div").focus();
                    }, 1000);
            },
        });
    });
}

export function getOverlappingBatesHelp(text = "Learn more about overlapping Bates setting") {
    return Dom.a(
        {
            href: "https://support.everlaw.com/hc/en-us/articles/360021902991#h_01G2DF4DDPNMVVSSCDNHQ8S1PK",
            target: "_blank",
            rel: "noopener noreferrer",
            class: "text-action action",
        },
        text,
    );
}

export interface HelpMenuHandle {
    setShow: (show: boolean) => void;
    setActive: (active: boolean) => void;
}

export interface HelpMenuProps {
    helpMenuIconRef: RefObject<Element>;
    handleRef: MutableRefObject<HelpMenuHandle>;
    setSettingsShowRef: MutableRefObject<(show: boolean) => void>;
}

interface HelpMenuRecommendationParams {
    helpIconNode: Element;
    helpMenuProps: HelpMenuProps;
}

export function initHelpMenuRecommendations(params: HelpMenuRecommendationParams): void {
    const { helpIconNode, helpMenuProps } = params;
    setEverId(helpIconNode, EVERID.HEADER.HELP_ICON);
    const introStep0Displayer: StepDisplayer<unknown> = {
        node: EVERID.HEADER.HELP_ICON,
        placement: [PopoverPlacement.BOTTOM_END],
        nextFunction: () => {
            helpMenuProps.handleRef.current.setActive(false);
            helpMenuProps.handleRef.current.setShow(true);
        },
        nextFunctionInverse: () => {
            helpMenuProps.handleRef.current.setActive(true);
            helpMenuProps.handleRef.current.setShow(false);
        },
        onRecommendationClose: () => {
            helpMenuProps.handleRef.current.setActive(true);
        },
    };
    const introStep1Displayer: StepDisplayer<unknown> = {
        node: EVERID.HEADER.HELP_ICON_SETTINGS,
        placement: [PopoverPlacement.LEFT_START],
        nextFunction: () => {
            helpMenuProps.setSettingsShowRef.current(true);
            helpMenuProps.handleRef.current.setActive(true);
            helpMenuProps.handleRef.current.setShow(false);
        },
        nextFunctionInverse: () => {
            helpMenuProps.setSettingsShowRef.current(false);
            helpMenuProps.handleRef.current.setActive(false);
            helpMenuProps.handleRef.current.setShow(true);
        },
        onRecommendationClose: () => {
            helpMenuProps.handleRef.current.setActive(true);
        },
    };
    Recommendations.WELCOME_TO_EVERLAW.getStep(0).reregisterDisplayer(
        Object.assign({}, introStep0Displayer, {
            onActivate: () => {
                Preference.RECPAGES.AllPages.setUserDefault(true);
                Preference.RECPAGES.DefaultSubset.setUserDefault(true);
            },
        }),
    );
    Recommendations.SMART_ONBOARDING_INTRO.getStep(0).reregisterDisplayer(introStep0Displayer);
    Recommendations.WELCOME_TO_EVERLAW.getStep(1).reregisterDisplayer(introStep1Displayer);
    Recommendations.SMART_ONBOARDING_INTRO.getStep(1).reregisterDisplayer(
        Object.assign({}, introStep1Displayer, {
            onActivate: () => {
                Preference.RECPAGES.AllPages.setUserDefault(false);
            },
        }),
    );
    Recommendations.DISABLE_RECOMMENDATIONS.getStep(0).reregisterDisplayer({
        node: EVERID.HEADER.HELP_ICON_SETTINGS,
        placement: [PopoverPlacement.LEFT, PopoverPlacement.LEFT_START],
        onActivate: () => {
            helpMenuProps.handleRef.current.setShow(true);
        },
    });
    Recommendations.WELCOME_TO_EVERLAW.getStep(3).reregisterDisplayer({
        isWalkMeTutorial: true,
        onActivate: () => {
            // initialize WalkMe
            WalkMe.loadWalkMe(false);
        },
        nextFunction: () => {
            WalkMe.startFlowByIdOrShowWarning(WalkMe.TutorialId.HOMEPAGE);
        },
    });
}

export const HelpMenu: FC<HelpMenuProps> = ({ helpMenuIconRef, handleRef, setSettingsShowRef }) => {
    const [show, setShow] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(true);
    const navigationPage = RecommendationNavigationPage.fromCurrentPage();
    useImperativeHandle(
        handleRef,
        () => {
            return { setShow, setActive };
        },
        [],
    );
    // We can only initialize the new help menu if we're on a page that supports recommendations.
    // We fall back on using the old help menu for pages that aren't supported yet.
    const initNewHelpMenu =
        navigationPage
        && navigationPage !== RecommendationNavigationPage.RecommendationNavigationPage.STYLE
        && JSP_PARAMS.Multiplex
        && !Project.CURRENT?.suspended;
    const onClickOrKeydown = useBrandedCallback(
        (e: Event) => {
            if (
                (e instanceof KeyboardEvent && e.key !== Input.ENTER && e.key !== Input.SPACE)
                || !active
            ) {
                return;
            }
            // TODO: add some state variable that checks whether we want to disable the
            // onClickdown. Should live in a ref.
            e.preventDefault();
            // Pages that are not in the context of a project can't use the recommendation help menu.
            // Instead, we'll fall back to the old menu for those pages.
            if (!initNewHelpMenu) {
                return import("Everlaw/HelpOverlay").then((HelpOverlay) => HelpOverlay.show());
            }
            // Wait until we know recommendations have loaded into the page before showing the
            // help menu.
            recommendationsLoaded.then(() => setShow(!show));
        },
        [active, initNewHelpMenu, show],
    );

    useEventListener(helpMenuIconRef, ["click", "keydown"], onClickOrKeydown);
    return (
        <>
            {initNewHelpMenu && (
                <RecommendationHelpMenu
                    show={show}
                    setShow={setShow}
                    setShowOptionsRef={setSettingsShowRef}
                    popoverTarget={helpMenuIconRef}
                />
            )}
        </>
    );
};

interface SupportPartnerAndOrg {
    /**
     * The {@link Partner} that supports the current {@link Project}. If not defined, the project
     * is not supported by a partner.
     */
    supportPartner?: Partner;
    /**
     * The {@link Organization} that handles support for the current project. See
     * {@link #getSupportPartnerAndOrganization} for details.
     */
    organization?: Organization;
}

export function getSupportPartnerAndOrganization(): SupportPartnerAndOrg {
    const supportPartner = Project.CURRENT?.partner?.providesSupport
        ? Project.CURRENT.partner
        : undefined;

    let org: Organization | undefined;
    if (supportPartner) {
        org = supportPartner.organization;
    } else if (Project.CURRENT) {
        org = Project.CURRENT.owningOrg;
    } else {
        org = CURRENT_ORG;
    }

    return { supportPartner, organization: org };
}

export function verifyClientDataAccessRequest(): Promise<void> {
    let entityId: number;
    let accessLevel: AccessLevel;
    if (Project.CURRENT) {
        entityId = Project.CURRENT.id;
        accessLevel = AccessLevel.PROJECT;
    } else if (CURRENT_ORG) {
        entityId = CURRENT_ORG.id;
        accessLevel = AccessLevel.ORGANIZATION;
    } else {
        throw new Error("Cannot verify access request on non-project or org page");
    }
    return Rest.post("/createClientOriginatedRequest.rest", {
        accessLevel: accessLevel,
        entityId: entityId,
        clientId: User.me?.id,
    });
}
