import { DialogSize, NonModal } from "design-system";
import * as React from "react";
import { FC, useCallback, useRef } from "react";

const DEFAULT_VIDEO_PLAYER_TITLE = "Video player";
const DEFAULT_PLAYER_HEIGHT = "536px";

interface VideoPlayerProps {
    title?: string;
    showPlayer: boolean;
    onPlayerClose: () => void;
    embeddedVideoURL?: string;
}

/**
 * Removes unwanted characters from the title of the video player.
 */
function cleanVideoTitle(title: string | undefined): string {
    if (!title) {
        return DEFAULT_VIDEO_PLAYER_TITLE;
    }
    // Substrings we want to replace in the title.
    const replaceRegex = /\[[^[\]]*]/g;
    return title.replaceAll(replaceRegex, "").trim();
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
    showPlayer,
    onPlayerClose,
    embeddedVideoURL,
    title,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    // If the Non-Modal dialog doesn't have a default height yet, we should give it one. Height
    // will then be up to the user after they resize the dialog.
    const setDefaultHeight = useCallback(() => {
        const dialogElement = ref.current?.parentNode?.parentElement;
        if (dialogElement && !dialogElement.style.height) {
            dialogElement.style.height = DEFAULT_PLAYER_HEIGHT;
        }
    }, []);
    return (
        <NonModal
            size={DialogSize.LARGE}
            title={cleanVideoTitle(title)}
            visible={showPlayer}
            onHide={onPlayerClose}
            onShow={setDefaultHeight}
            resizable={true}
        >
            <div ref={ref} className={"video-player"}>
                <iframe
                    className={"video-player__iframe"}
                    src={embeddedVideoURL}
                    sandbox={"allow-same-origin allow-scripts allow-presentation allow-popups"}
                    allow={"autoplay; fullscreen; picture-in-picture; same-origin"}
                />
            </div>
        </NonModal>
    );
};
