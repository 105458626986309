import Base = require("Everlaw/Base");
import { UserResultsTableView } from "Everlaw/UserResultsTableView";

class ResultsTableView extends Base.Object implements ResultsTableView.LoadableView {
    get className() {
        return "ResultsTableView";
    }
    override id: ResultsTableView.Id;
    columns: string[][];
    private sort: any[];
    private userView: UserResultsTableView;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.userView = Base.set(UserResultsTableView, this.userView);
    }
    // ResultsTableView has the columns but not the widths. this.userView has each
    // column's width. Here we throw this.userView into a hashtable mapping each column to its
    // width, and then use that mapping to add the width to this.columns to get the visible
    // columns array we can pass into Grids.ts to load those columns.
    getColumnsWithWidths() {
        // couldn't find a better way to combine the two strings into a valid key type for Map
        function getKey(s1, s2) {
            return s1 + "~" + s2;
        }
        const widthsByClassAndId = new Map<string, number>();
        this.userView.columnWidths.forEach((c) => {
            return widthsByClassAndId.set(getKey(c[0], c[1]), c[2]);
        });
        return this.columns.map((col) => {
            return [col[0], col[1], widthsByClassAndId.get(getKey(col[0], col[1])) || 0];
        });
    }
    getSort() {
        return this.sort;
    }
}

module ResultsTableView {
    export type Id = number & Base.Id<"ResultsTableView">;

    export const VIEW_TYPE_MINE = "my_views";
    export const VIEW_TYPE_SHARED = "shared_views";
    export const VIEW_TYPE_DEFAULT = "default_view";

    export interface LoadableView {
        getColumnsWithWidths: () => (string | number)[][];
        getSort: () => any[];
    }
}

export = ResultsTableView;
