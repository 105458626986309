import { Constants as C } from "Everlaw/Constants";
import { Channel } from "Everlaw/Core";
import * as Is from "Everlaw/Core/Is";
import * as Dom from "Everlaw/Dom";
import * as Util from "Everlaw/Util";
import dojo_on = require("dojo/on");

export enum MediaType {
    AUDIO = "audio",
    VIDEO = "video",
}

export interface MediaPlayerParams {
    node: HTMLElement;
    mediaType: MediaType;
    url: string;
    onMediaLoad: () => void;
    onMediaLoadError?: () => void;
    styleClass?: string;
    // If set, player will try to auto-play the media muted upon auto-play failure.
    mutedAutoPlay?: boolean;
}

/**
 * Wrapper class around an HTMLMediaElement. Supports a list of fall-back URLs and a callback when
 * the file finishes loading in the browser (or fails to load, for some reason).
 */
export class MediaPlayer {
    readonly node: HTMLElement;
    protected readonly player: HTMLMediaElement;
    private readonly loadTimer: { unsubscribe: () => void };
    private readonly clipPlayer: ClipPlayer;
    private callbackTimer = -1;
    private readonly timerCallbacks = new Channel<{}>();
    private readonly updateCallbacks = new Channel<{}>();
    private lastUpdate = -1;
    protected static TICK = 100; // ms
    private readonly mutedAutoPlay: boolean;

    protected readonly toDestroy: Util.Destroyable[] = [];

    constructor(params: MediaPlayerParams) {
        this.node = params.node;
        const classes = ["media-player"];
        if (Is.defined(params.styleClass)) {
            classes.push(params.styleClass);
        }
        Dom.addClass(this.node, classes);
        this.mutedAutoPlay = params.mutedAutoPlay;

        const source = Dom.create("source", {
            src: params.url,
            type: params.mediaType + "/mp4",
        });

        Dom.place(
            (this.player = Dom.create(params.mediaType, { controls: true, content: source })),
            this.node,
        );

        if (params.onMediaLoadError) {
            // Set up an error listener in case the media fails to load.
            this.toDestroy.push(dojo_on(source, "error", () => params.onMediaLoadError()));
        }

        // We need to poll until we can read the duration from the player element, which guarantees
        // it has loaded properly.
        this.loadTimer = this.addTimerCallback(() => {
            if (this.player.duration) {
                this.onLoad(params.onMediaLoad);
            }
        });

        this.clipPlayer = new ClipPlayer(this);
    }

    protected onLoad(callback?: () => void): void {
        this.removeTimerCallback(this.loadTimer);
        this.isVideo()
            ? Dom.addClass(this.player, "video-player")
            : Dom.addClass(this.player, "audio-player");

        callback?.();
    }

    isLoaded(): boolean {
        return !!this.player.duration;
    }

    /**
     * Returns NaN during this.player loading.
     * Should only be called when this.player is completely loaded.
     */
    getDuration(): number {
        return this.player.duration;
    }

    getMediaType(): string {
        return this.player.tagName.toLowerCase();
    }

    /**
     * Should only be called when this.player is completely loaded.
     */
    isVideo(): boolean {
        // If the video element does not have videoWidth/videoHeight, then the video is audio-only.
        // In this case, video player is the same as audio player.
        if (this.getMediaType() === MediaType.VIDEO) {
            return this.hasVideoResolution();
        }
        return false;
    }

    /**
     * Media uploaded before Release 70.0 may be clipped.
     * Should only be called when this.player is completely loaded.
     */
    couldBeClipped(): boolean {
        // When we used AWS transcoding anything > 8hrs
        // or between 1:00 and 1:02 may have been clipped.
        // Since Release 70 this is not the case, but could still apply for old uploads.
        return (
            this.player.duration > 8 * C.HRS
            || (this.player.duration > C.HRS && this.player.duration < C.HRS + 2 * C.MINS)
        );
    }

    /**
     * Different browsers have different behaviors when
     * 1. users set this.player.currentTime before video finishes loading.
     * 2. users try to set this.player.currentTime to values outside [0, video_duration]
     * Therefore, we
     * 1. prevent users from setting this.player.currentTime before video is completed loaded,
     * 2. set this.player.currentTime to clamp(input_time, 0, video_duration).
     */
    setTime(time: number, resetClipPlayer = false): void {
        if (!this.isLoaded()) {
            return;
        }
        this.player.currentTime = Util.clamp(time, 0, this.getDuration());
        // If currently playing a clip, calling setTime indicates user is done with clip
        resetClipPlayer && this.resetPlayClip();
    }

    toEnd(): void {
        this.setTime(C.INTMAX);
    }

    /**
     * Returns 0 while this.player is loading.
     */
    getCurrentTime(): number {
        return this.player.currentTime;
    }

    /**
     * Can be called before this.player is completely loaded. However, browsers have different
     * policies for media play upon load. Chrome: https://developer.chrome.com/blog/autoplay/
     * Firefox: https://support.mozilla.org/en-US/kb/block-autoplay
     */
    play(): Promise<void> {
        // In modern browsers, HTMLMediaElement#play() returns a promise, but older (pre-2019)
        // browsers will return undefined. Wrap the call in Promise.resolve to make it consistent.
        return Promise.resolve(
            this.player
                .play()
                ?.catch((error) => {
                    /*
             When error.name === "AbortError",
             pause was called or the player was otherwise reset before the 'play' action
             could complete. See https://developer.chrome.com/blog/play-request-was-interrupted/
             In our case, we don't need to do anything because we already have to check
             the player state rather than assuming play was successful, since the player can
             generally be controlled from several places, so swallow this error.
             */
                    if (!(error instanceof DOMException && error.name === "AbortError")) {
                        throw error;
                    }
                })
                .catch((error) => {
                    /*
             When error.name === "NotAllowedError",
             play call is blocked by user browser's security & privacy settings. This often
             happens when we autoplay a video or audio upon page load without user interaction.
             if this.mutedAutoPlay is set, we mute the media and try to play again. If it still fails,
             we don't do anything.
             */
                    if (!this.isNotAllowedError(error)) {
                        throw error;
                    } else if (this.mutedAutoPlay) {
                        this.mute();
                        return this.player.play().catch((error) => {
                            if (!this.isNotAllowedError(error)) {
                                throw error;
                            }
                        });
                    }
                }),
        );
    }

    private isNotAllowedError(error: Error): boolean {
        return error instanceof DOMException && error.name === "NotAllowedError";
    }

    playClip(clip: Clip, update?: (isPlaying: boolean) => void, resetFirst?: boolean): void {
        this.clipPlayer.playClip(clip, update, resetFirst);
    }

    isInClip(): boolean {
        return this.clipPlayer.isInClip();
    }

    resetPlayClip(): void {
        this.clipPlayer.reset();
    }

    /**
     * Can be called before this.player is completely loaded.
     */
    pause(): void {
        this.player.pause();
    }

    /**
     * Can be called before this.player is completely loaded. Returns true before this.player
     * is completed loaded.
     */
    isPaused(): boolean {
        return this.player.paused;
    }

    /**
     * Can be called before this.player is completely loaded. Returns false before this.player
     * is completed loaded.
     */
    isEnded(): boolean {
        return this.player.ended;
    }

    /**
     * Can be called before this.player is completely loaded.
     */
    toggle(): void {
        this.isPaused() ? this.play() : this.pause();
    }

    /**
     * Can be called before this.player is completely loaded.
     */
    mute(): void {
        this.player.muted = true;
    }

    /**
     * Can be called before this.player is completely loaded.
     */
    unmute(): void {
        this.player.muted = false;
    }

    /**
     * Style the player to be no larger than the given dimensions
     * This method will maintain aspect ratio for video and scale to whichever limit controls.
     * Should only be called after this.player is completly loaded, since we only know whether
     * we have audio or video player after loading finishes.
     * @param maxWidth - (pixels) The player should be no wider than this
     * @param maxHeight - (pixels) The player should be no taller than this
     */
    setMaxDim(maxWidth: number, maxHeight: number): void {
        if (!this.isLoaded()) {
            return;
        }
        // If this is an audio player or video player but audio only, we only set width since
        // audio player and audio-only player are both a audio player bar.
        if (!this.isVideo()) {
            this.style({
                width: 0.8 * maxWidth + "px",
                height: null,
            });
            return;
        }

        const videoRes = this.getVideoResolution();
        let w = maxWidth;
        let h = maxHeight;
        const wRatio = maxWidth / videoRes.w;
        const hRatio = maxHeight / videoRes.h;
        if (wRatio < hRatio) {
            // if width controls
            h = (w * videoRes.h) / videoRes.w;
        } else {
            w = (h * videoRes.w) / videoRes.h;
        }
        this.style({
            width: w + "px",
            height: h + "px",
        });
    }

    /**
     * Should only be called after this.player is completly loaded, since we only know whether we have
     * audio or video player after loading finishes.
     */
    style(styleProps: Dom.StyleProps): void {
        if (!this.isLoaded()) {
            return;
        }
        Dom.style(this.node, styleProps);
        Dom.style(this.player, styleProps);
    }

    /**
     * Return resolution for current video. Returns undefined if this is a audio player.
     */
    getVideoResolution(): { w: number; h: number } {
        const videoElem = <HTMLVideoElement>this.player;
        return { w: videoElem.videoWidth, h: videoElem.videoHeight };
    }

    hasVideoResolution(): boolean {
        const videoElem = <HTMLVideoElement>this.player;
        return !!videoElem.videoWidth && !!videoElem.videoHeight;
    }

    /**
     * Can be called before this.player is completely loaded.
     */
    addVolumeListener(listener: () => void): void {
        this.player.addEventListener("volumechange", listener);
    }

    /**
     * Can be called before this.player is completely loaded. Returns 1 before this.player is
     * completely loaded.
     */
    getVolume(): number {
        return this.player.volume;
    }

    /**
     * Add a callback to be run every 100 ms (by default) or only when player time updates.
     * Can be called before this.player is completely loaded.
     */
    addTimerCallback(callback: () => void, updateOnly?: boolean): { unsubscribe: () => void } {
        if (!this.hasTimerCallback()) {
            this.callbackTimer = setInterval(() => {
                // Always call these
                this.timerCallbacks.publish({});
                const time = this.getCurrentTime();
                // Call these only if time has changed
                time !== this.lastUpdate && this.updateCallbacks.publish({});
                this.lastUpdate = time;
            }, MediaPlayer.TICK);
        }

        if (updateOnly) {
            return { unsubscribe: this.updateCallbacks.subscribe(callback) };
        } else {
            return { unsubscribe: this.timerCallbacks.subscribe(callback) };
        }
    }

    removeTimerCallback(subscription: { unsubscribe: () => void }): void {
        subscription.unsubscribe();
        if (!this.timerCallbacks.hasSubscription() && !this.updateCallbacks.hasSubscription()) {
            this.resetTimerCallback();
        }
    }

    private resetTimerCallback(): void {
        clearInterval(this.callbackTimer);
        this.callbackTimer = -1;
    }

    private hasTimerCallback(): boolean {
        return this.callbackTimer !== -1;
    }

    /**
     * Can be called before this.player is completely loaded.
     */
    setPlaybackSpeed(speed: number): void {
        this.player.playbackRate = speed;
    }

    destroy(): void {
        this.timerCallbacks.unsubscribeAll();
        this.updateCallbacks.unsubscribeAll();
        this.clipPlayer.reset();
        Util.destroy(this.toDestroy);
    }
}

export const TIME_NOT_SET = -1;

export interface Clip {
    id: string | number; // Used for disambiguation when checking clip equality
    start: number; // seconds
    end: number; // seconds
}

/**
 * Tool which can be used to play only a certain range of a piece of media
 * rather than the whole thing.
 */
class ClipPlayer {
    private clip: Clip;
    private update: (playing: boolean) => void;
    private timer: number; // timer reference
    private player: MediaPlayer;
    private readonly INTERVAL = 100; // ms

    constructor(player: MediaPlayer) {
        this.player = player;
        this.reset();
    }

    /**
     * Play a range of the media in the connected player
     * @param clip
     * @param update: will be called frequently with updates on if the clip is playing
     * @param resetFirst: reset the previous clip play when playing new clip
     */
    playClip(clip: Clip, update?: (playing: boolean) => void, resetFirst = false): void {
        resetFirst && this.reset();

        if (
            !clip
            || !(Is.number(clip.id) || Is.string(clip.id))
            || (clip.start !== TIME_NOT_SET && clip.end !== TIME_NOT_SET && clip.start > clip.end)
        ) {
            update?.(false);
            return;
        }

        // if current clip, toggle play/pause. Else reset and start new
        if (
            clip.id === this.clip.id
            && clip.start === this.clip.start
            && clip.end === this.clip.end
        ) {
            // User clicked clip play/pause icon while clip is active and unchanged
            this.player.toggle();
            this.update?.(!this.player.isPaused());
            return;
        }
        // Play a new clip
        this.timer && clearTimeout(this.timer);
        // Tell old clip source it's not playing anymore
        this.update?.(false);

        // Save new clip
        const prevCip = this.clip;
        this.clip = clip;
        this.update = update;
        // Set player to start of clip.
        this.player.setTime(clip.start);
        // this.clip.id === clip.id means we already set the same clip before, but currently we
        // have different clip.start and clip.end. In this case, we simply toggle video if it's
        // already playing.
        this.clip.id === prevCip.id ? this.player.toggle() : this.player.play();

        this.timer = setInterval(() => {
            const now = this.player.getCurrentTime();
            if (this.isOutsideClip(now)) {
                // If user has scrubbed outside clip range, assume they don't want clip anymore
                this.update?.(!this.player.isPaused());
                this.reset();
            } else if (this.isEndOfClip(now)) {
                // If reached end of clip naturally
                this.player.pause();
                this.update?.(!this.player.isPaused());
                this.reset();
            } else if (this.player.isEnded()) {
                this.reset();
            } else {
                this.update?.(!this.player.isPaused());
            }
        }, this.INTERVAL);
    }

    private isOutsideClip(now: number): boolean {
        return (
            this.clip.start > now
            || (this.clip.end !== TIME_NOT_SET && now - this.clip.end > (2 * this.INTERVAL) / C.SEC)
        );
    }

    private isEndOfClip(now: number): boolean {
        return this.clip.end !== TIME_NOT_SET && now > this.clip.end - this.INTERVAL / C.SEC;
    }

    isInClip(): boolean {
        const now = this.player.getCurrentTime();
        return (
            this.clip
            && this.clip.id !== null
            && now >= this.clip.start
            && (this.clip.end === TIME_NOT_SET || now <= this.clip.end)
        );
    }

    reset(): void {
        this.timer && clearTimeout(this.timer);
        // Tell old clip source it's not playing anymore
        this.update?.(false);
        this.update = null;
        this.clip = {
            id: null,
            start: TIME_NOT_SET,
            end: TIME_NOT_SET,
        };
    }
}
