import Base = require("Everlaw/Base");
import ChronologyProfile = require("Everlaw/Chron/ChronologyProfile");
import ChronWorkEntryRelationship = require("Everlaw/Chron/ChronologyWorkEntryRelationship");
import DateUtil = require("Everlaw/DateUtil");
import { TimezoneN } from "Everlaw/DateUtil";
import Project = require("Everlaw/Project");
import { getProjectMomentJSDateFormat } from "Everlaw/ProjectDateUtil";

class ChronologyWorkEntry extends Base.Object {
    chronProfileId: number;
    role: string;
    organization: string;
    begin: number;
    end: number;

    constructor(params: any) {
        super(params);
        this._mixin(params);
        params.relationships && Base.set(ChronWorkEntryRelationship, params.relationships);
    }

    get className() {
        return "ChronologyWorkEntry";
    }

    override _mixin(params: any) {
        Object.assign(this, params);
    }

    override display() {
        return this.role || this.organization;
    }

    durationDisplay() {
        if (!this.begin && !this.end) {
            return "(No date range)";
        }
        const dateDisplayFormat = getProjectMomentJSDateFormat();
        const beginDisp = this.begin
            ? DateUtil.displayDateTimeAsZoneWithFormat(
                  this.begin,
                  Project.CURRENT.timezoneId as TimezoneN,
                  dateDisplayFormat,
              )
            : "?";
        const endDisp = this.end
            ? DateUtil.displayDateTimeAsZoneWithFormat(
                  this.end,
                  Project.CURRENT.timezoneId as TimezoneN,
                  dateDisplayFormat,
              )
            : "?";
        return beginDisp + " - " + endDisp;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ChronologyWorkEntry {
    export function getWorkEntries(profile: ChronologyProfile) {
        return Base.get(ChronologyWorkEntry).filter((workEntry) => {
            return workEntry.chronProfileId === profile.id;
        });
    }

    export interface UpdateParams {
        role?: string;
        organization?: string;
        begin?: number;
        end?: number;
    }
}

export = ChronologyWorkEntry;
