import { PopoverMenu, PopoverMenuOptionProps } from "design-system";
import * as React from "react";
import { FC } from "react";

/**
 * This component allows us to use {@link PopoverMenu.Option}s in the generic popover
 * {@link RecommendationHelpMenu} by extending each option into the padding of the base menu.
 */
export const HelpMenuOption: FC<PopoverMenuOptionProps> = (props) => {
    return (
        <div className={"help-menu-option"}>
            <PopoverMenu.Option
                {...props}
                label={<div className={"help-menu-option__label-wrapper"}>{props.label}</div>}
            />
        </div>
    );
};
